import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { StaticQuery } from 'gatsby';
import Twitter from './_children/twitter';
import OpenGraph from './_children/openGraph';

function HtmlHead({
  title,
  description,
  image,
  pathname,
  isArticle,
  article = false,
}) {

  return (
    <StaticQuery
      query={graphql`
        query SEOQuery {
          site {
            siteMetadata {
              defaultTitle
              titleTemplate
              defaultImage
              defaultDescription
              siteUrl
              twitterUsername
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            titleTemplate,
            defaultDescription,
            defaultImage,
            siteUrl,
            twitterUsername,
          },
        },
      }) => {
        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: `${siteUrl}${image || defaultImage}`,
          url: `${siteUrl}${pathname || '/'}`,
        };

        return (
          <>
            <Helmet title={seo.title} titleTemplate={titleTemplate}>
              <meta name="description" content={seo.description} />
              <meta name="image" content={seo.image} />
              {/* {
                pathname === '/contacta' &&
                <script src="https://www.google.com/recaptcha/api.js?render=6LfzUs4bAAAAAKb_HJJk6PkvoMTwgrwFDplLjzqA"></script>
              } */}
            </Helmet>
            <Twitter
              username={twitterUsername}
              title={seo.title}
              description={seo.description}
              image={seo.image}
            />
            <OpenGraph
              title={seo.title}
              isArticle={isArticle}
              description={seo.description}
              image={seo.image}
            />
          </>
        );
      }}
    />
  )
};

HtmlHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
};

export default HtmlHead;